const condicion = [
  'CONTINUADOR',
  'NUEVO'
]

const medio = [
  'AFICHE',
  'AMISTAD',
  'BANNER / BANDEROLA',
  'CENTRAL TELEFONICA MINSA',
  'FOLDER',
  'FAMILIAR',
  'OTRAS PAGINAS DE INTERNET',
  'NO BRINDA DATO',
  'OTROS MATERIALES Y MEDIOS DE DIFUSIÓN',
  'OTRAS INSTITUCIONES PÚBLICAS O PRIVADAS',
  'PERIODICO',
  'PERSONAL MINSA',
  'PERSONAL DE OTRAS INSTITUCIONES',
  'RADIO',
  'REDES SOCIALES',
  'REVISTA',
  'TELEVISIÓN',
  'VOLANTE / DIPTICO/ TRIPTICO',
  'PAGINA WEN MINSA'
]

const opcionMenu = [
  'OPCIÓN 1: VACUNACIÓN',
  'OPCIÓN 2: INFORMACIÓN SOBRE CORONAVIRUS O REPORTAR UN CASO SOSPECHOSO',
  'OPCIÓN 3.1:  PLANIFICACIÓN FAMILIAR',
  'OPCIÓN 3.2: NUTRICIÓN',
  'OPCIÓN 3.3.: MEDICINA',
  'OPCIÓN 4.: AFILIACIÓN SIS',
  'OPCIÓN 5: PSICOLOGÍA',
  'OPCIÓN 6:OTRAS CONSULTAS',
  'OPCIÓN 7: COMUNICACIÓN CON SUPERINTENDENCIA'
]

const derivacionLlamada = [
  'ENFERMERÍA',
  'CORONAVIRUS',
  'NUTRICIÓN',
  'SALUD MENTAL',
  'OBSTETRICIA',
  'OTRAS CONSULTAS',
  'SIS',
  'MEDICINA'
]

const tipoEnfermedad = [
  'TRANSMISIBLE',
  'NO TRANSMISIBLE',
  'N.A.'
]

export default {
  condicion,
  medio,
  opcionMenu,
  derivacionLlamada,
  tipoEnfermedad
}
