<template>
  <ba-container>
    <ba-toolbar
      :toolbarProps="toolbarProps"
      @save="submit()"
      @back="goToList()"
    />
    <h4
      v-if="caseObject"
      class="mx-5 my-3 pt-3 row"
    >N° {{ caseObject.refnum }} | <ba-sla-chip
        :value="caseObject.porcentaje"
        :sla="caseObject.duedate"
        :type="'detail'"
      > {{ !caseObject.duedate || caseObject.finalizado === 1 ?  $t('case.components.table.withoutSla') :  caseObject.duedate }} </ba-sla-chip>
    </h4>
    <ba-case-basic-info
      v-model="basicInfo"
      :isEdit="isEdit"
      ref="basicInfoComponent"
    />
    <div v-if="caseObject">
      <ba-expansion
        :title="$t('contact.components.dataInfo.title')"
        ref="dataExpansion"
      >
        <ba-case-data-info
          v-model="dataInfo"
          :idTypeObject="1"
          ref="dataInfoComponent"
        ></ba-case-data-info>
      </ba-expansion>
      <ba-expansion
        :title="$t('contact.components.notes.title')"
        :icon="'mdi-clipboard-edit'"
        ref="notesExpansion"
      >
        <ba-notes
          :idObject="caseObject.id"
          :typeObject="'casos'"
        />
      </ba-expansion>
      <div>
        <h4 class="mx-5 my-3 pt-3">Estado del proceso</h4>
        <div class="text-center">
          <v-btn
            v-if="caseObject.idworkflow && caseObject.primerpaso === 0"
            class="mx-1"
            rounded
            color="primary"
            dark
            small
            @click="handleStep(caseObject.id, 'back')"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
            Retroceder
          </v-btn>
          <v-btn
            v-if="caseObject.idworkflow && caseObject.finalizado === 0"
            class="mx-1"
            rounded
            color="primary"
            dark
            small
            @click="handleStep(caseObject.id, 'next')"
          >
            Avanzar
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </ba-container>
</template>

<script>
import i18n from '@/plugins/i18n'
import BaToolbar from './../../../components/Toolbar.vue'
import BaContainer from './../../../components/Container.vue'
import BaCaseBasicInfo from './components/BasicInformation.vue'
import BaCaseDataInfo from './components/DataInformation.vue'
import BaExpansion from './../../../components/Expansion.vue'
import BaNotes from './../../../components/Notes.vue'
import BaChip from './../../../components/SLAChip.vue'
export default {
  components: {
    'ba-toolbar': BaToolbar,
    'ba-container': BaContainer,
    'ba-expansion': BaExpansion,
    'ba-notes': BaNotes,
    'ba-case-basic-info': BaCaseBasicInfo,
    'ba-case-data-info': BaCaseDataInfo,
    'ba-sla-chip': BaChip
  },
  data: () => {
    return {
      toolbarProps: {
        title: i18n.t('case.components.toolbar.titleCreate'),
        actions: [
          {
            type: 'back',
            label: i18n.t('case.components.toolbar.backButton'),
            icon: 'mdi-arrow-left-bold',
            route: 'case-list',
            color: 'secondary'
          },
          {
            type: 'save',
            label: i18n.t('case.components.toolbar.saveButton'),
            icon: 'mdi-content-save',
            route: 'case',
            color: 'primary'
          }
        ]
      },
      basicInfo: {
        idcontactodesc: '',
        idusuariocreaciondesc: '',
        idcontacto: 0,
        idproducto: 0,
        idtipo: 0,
        idsubtipo: 0,
        idestado: 1,
        idusuarioasignado: 0,
        asunto: ''
      },
      dataInfo: {
        condicion: '',
        medio: '',
        opcion_menu: '',
        derivacion_llamada: '',
        tipo_enfermedad: '',
        resultado_sms: ''
      },
      notes: [],
      caseObject: null,
      isEdit: false,
      contact: null,
      user: null
    }
  },
  methods: {
    submit () {
      if (!this.caseObject) {
        if (this.$refs.basicInfoComponent.validateForm()) {
          this.saveCase()
        } else {
          this.$store.dispatch('snackbar/alert', { type: 'error', msg: this.$t('validations.snackbar.requiredMessage') })
        }
      } else {
        this.$refs.dataExpansion.open() //  TODO: ver mejora se requiere que el panel se expanda para poder ralizar las sigientes validaciones
        setTimeout(() => {
          if (this.$refs.basicInfoComponent.validateForm() && this.$refs.dataInfoComponent.validateForm()) {
            this.updateCase()
          } else if (!this.$refs.dataInfoComponent.validateForm()) {
            this.$store.dispatch('snackbar/alert', { type: 'error', msg: this.$t('validations.snackbar.requiredMessageCf') })
          } else {
            this.$store.dispatch('snackbar/alert', { type: 'error', msg: this.$t('validations.snackbar.requiredMessage') })
          }
        }, 1000)
      }
    },
    saveCase () {
      this.basicInfo.idcontacto = this.contact.id
      const data = {
        ...this.basicInfo,
        origen: this.$store.getters.getChannel,
        sistemaexterno: this.$store.getters.getPlatform,
        canal: this.$store.getters.getChannel,
        idinteraccion: this.$store.getters.getInteraction,
        urlorigin: window.location.hostname
      }
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('caseStore/addCase', data).then(response => {
        this.caseObject = response
        this.saveNoteAuto(this.caseObject)
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    updateCase () {
      const cf = {
        ...this.caseObject.cf,
        ...this.dataInfo
      }
      this.caseObject.cf = cf
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('caseStore/updateCase', this.caseObject).then(response => {
        this.$store.dispatch('loader/done')
        this.$store.dispatch('snackbar/alert', { type: 'success', msg: this.$t('contact.components.snackbar.updateCase') })
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    saveNoteAuto (caseObject) {
      let url = ''
      let message = ''
      if (caseObject.sistemaexterno === 'purecloud' || caseObject.sistemaexterno === 'genesys') {
        url = 'https://apps.mypurecloud.com/directory/#/engage/admin/interactions/' + caseObject.idinteraccion
        message = `<b>${this.$t('case.components.notes.firstNote')}</b></br>${this.$t('case.components.notes.idconversation')}: ${caseObject.idinteraccion}</br><a class="ver_detalle" href="${url}" target="_blank">${this.$t('case.components.notes.seeDetail')} </a>`
      } else if (caseObject.sistemaexterno === 'chattigo' || caseObject.sistemaexterno === 'basocial') {
        message = `<b>${this.$t('case.components.notes.firstNote')}</b></br>${this.$t('case.components.notes.idconversation')}: ${caseObject.idinteraccion}`
      } else {
        message = `<b>${this.$t('case.components.notes.firstNote')}</b></br>${this.$t('case.components.notes.idconversation')}: ${caseObject.idinteraccion}`
      }
      const note = {
        privado: 0,
        texto: message,
        idobjeto: caseObject.id,
        tipoobjeto: 'casos'
      }
      this.$store.dispatch('note/addNote', note).then(note => {
        this.$store.dispatch('loader/done')
        this.$store.dispatch('snackbar/alert', { type: 'success', msg: this.$t('contact.components.snackbar.addCase') })
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    getContact (contactId) {
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('contact/getContact', { id: contactId }).then(contact => {
        this.$store.dispatch('loader/done')
        this.contact = contact
        this.basicInfo.idcontacto = this.contact.id
        this.basicInfo.idcontactodesc = `${this.contact.nombre} ${this.contact.apellido}`
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    getCase (caseId) {
      const data = {
        id: caseId,
        method: ['tipificacion']
      }
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('caseStore/getCase', data).then(response => {
        this.$store.dispatch('loader/done')
        this.caseObject = response.data
        this.basicInfo = this.caseObject
        this.dataInfo = this.caseObject.cf
        this.getContact(this.caseObject.idcontacto)
        this.$store.dispatch('loader/done')
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    handleStep (caseId, action) {
      if (action === 'next') {
        this.updateChecklist(caseId).then(response => { // check background mode
          this.$store.dispatch('process/nextStep', { id: caseId }).then(response => {
            this.$store.dispatch('snackbar/alert', { type: 'success', msg: this.$t('contact.components.snackbar.updateStep') })
            this.$store.dispatch('loader/done')
            this.goToList()
          }).catch(error => {
            this.$store.dispatch('loader/done')
            console.log(error)
          })
        }).catch(error => {
          this.$store.dispatch('loader/done')
          console.log(error)
        })
      } else {
        this.$store.dispatch('loader/pending')
        this.$store.dispatch('process/backStep', { id: caseId }).then(response => {
          this.$store.dispatch('snackbar/alert', { type: 'success', msg: this.$t('contact.components.snackbar.updateStep') })
          this.$store.dispatch('loader/done')
          this.goToList()
        }).catch(error => {
          this.$store.dispatch('loader/done')
          console.log(error)
        })
      }
    },
    updateChecklist (caseId) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('loader/pending')
        this.$store.dispatch('checklist/getAllCheck', { idcaso: caseId }).then(response => {
          const checklist = response
          const requestChecked = []
          checklist.forEach(check => {
            if (check.estadocaso === 0) {
              const checkitem = {
                idcaso: caseId,
                idtarea: check.id,
                value: true,
                origin: window.location.hostname
              }
              requestChecked.push(
                this.$store.dispatch('checklist/check', checkitem)
              )
            }
          })
          Promise.all(requestChecked).then(response => {
            resolve(response)
          }).then(error => {
            reject(error)
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    goToList () {
      this.$router.push({ name: 'externalContact', query: { contactId: this.contact.id } })
    },
    init () {
      const caseId = this.$route.query.caseId
      const contactId = this.$route.query.contactId
      if (caseId) {
        this.toolbarProps.title = i18n.t('case.components.toolbar.titleEdit')
        this.isEdit = true
        this.getCase(caseId)
      } else {
        this.getContact(contactId)
      }
    }
  },
  beforeMount () {
    this.init()
  }
}
</script>

<style>
</style>
