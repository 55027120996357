<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-row class="mx-1">
      <v-col
        v-for="(item, index) of customFields"
        :key="index"
        cols="12"
        sm="12"
        md="6"
      >
        <v-text-field
          :class="{'required-label' : item.validacion === 1}"
          v-if="item.nombre === 'resultado_sms'"
          :label="item.label"
          v-model="value[item.nombre]"
          disabled
          outlined
        ></v-text-field>

        <v-select
          v-else
          :class="{'required-label' : item.validacion === 1}"
          :label="item.label"
          v-model="value[item.nombre]"
          :items="select[getArrayName(item)]"
          :rules="item.validacion === 1 ? required : []"
          outlined
        ></v-select>

      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import DataInfoSelect from './DataInformation'
import { required } from './../../../../utils/validations'
export default {
  name: 'ba-case-data-info',
  props: {
    value: Object,
    idTypeObject: Number // opitons --> 1: case,  2: contact, 3: account
  },
  data: () => {
    return {
      baseTranslate: 'case.components.dataInfo',
      select: DataInfoSelect,
      customFields: [],
      required: required
    }
  },
  watch: {
    value: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  methods: {
    getCustomFields () {
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('customFields/getAllCustomFields', { idtipoobjeto: this.idTypeObject }).then(customFields => {
        this.customFields = customFields
        this.$store.dispatch('loader/done')
      }).catch(err => {
        this.$store.dispatch('loader/done')
        console.log(err)
      })
    },
    getArrayName (item) {
      return item.nombre.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '') // parse to camelcase
    },
    validateForm () {
      return this.$refs.form.validate()
    }
  },
  mounted () {
    this.getCustomFields()
  }
}
</script>

<style>
</style>
