<template>
  <v-form
    class="mt-4"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row class="mx-1">
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-text-field
          :label="$t(`${baseTranslate}.contact`)"
          v-model="value.idcontactodesc"
          outlined
          disabled
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <!-- <v-select
          :label="$t(`${baseTranslate}.product`)"
          v-model="value.idproducto"
          :rules="required"
          :items="products"
          item-text="nombre"
          item-value="id"
          @change="getTypes()"
          outlined
          required
        ></v-select> -->
        <v-autocomplete
          class="required-label"
          :label="$t(`${baseTranslate}.product`)"
          v-model="value.idproducto"
          :rules="required"
          :items="products"
          :item-text="'nombre'"
          :item-value="'id'"
          @change="getTypes()"
          cache-items
          hide-no-data
          hide-selected
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <!-- <v-select
          :label="$t(`${baseTranslate}.motive`)"
          v-model="value.idtipo"
          :rules="required"
          :items="types"
          item-text="nombre"
          item-value="id"
          @change="getSubtypes()"
          outlined
          :disabled="types.length === 0"
        ></v-select> -->
        <v-autocomplete
          class="required-label"
          :label="$t(`${baseTranslate}.motive`)"
          v-model="value.idtipo"
          :rules="required"
          :items="types"
          :item-text="'nombre'"
          :item-value="'id'"
          @change="getSubtypes()"
          cache-items
          hide-no-data
          hide-selected
          outlined
          :disabled="types.length === 0"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <!-- <v-select
          :label="$t(`${baseTranslate}.submotive`)"
          v-model="value.idsubtipo"
          :rules="required"
          :items="subtypes"
          item-text="nombres"
          item-value="id"
          outlined
          :disabled="subtypes.length === 0"
        ></v-select> -->
        <v-autocomplete
          :label="$t(`${baseTranslate}.submotive`)"
          class="required-label"
          v-model="value.idsubtipo"
          :rules="required"
          :items="subtypes"
          :item-text="'nombres'"
          :item-value="'id'"
          cache-items
          hide-no-data
          hide-selected
          outlined
          :disabled="subtypes.length === 0"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          :label="$t(`${baseTranslate}.state`)"
          v-model="value.idestado"
          :items="states"
          item-text="descripcion"
          item-value="id"
          outlined
          :disabled="!isEdit"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-autocomplete
          :label="$t(`${baseTranslate}.userAsigned`)"
          v-model="userAssigned"
          :rules="required"
          :loading="loading"
          :items="users"
          :item-text="'name'"
          :item-value="'id'"
          :search-input.sync="search"
          cache-items
          hide-no-data
          hide-selected
          outlined
          return-object
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-text-field
          class="required-label"
          :label="$t(`${baseTranslate}.issue`)"
          v-model="value.asunto"
          :rules="required"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required } from './../../../../utils/validations'
export default {
  name: 'ba-case-basic-info',
  props: {
    value: Object,
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      valid: true,
      baseTranslate: 'case.components.basicInfo',
      products: [],
      types: [],
      subtypes: [],
      states: [],
      users: [],
      required: required,
      search: null,
      loading: false,
      userAssigned: null
    }
  },
  methods: {
    getProducts () {
      const data = {
        idcontacto: this.value.idcontacto
      }
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('typing/getProducts', data).then(response => {
        this.products = response
        this.$store.dispatch('loader/done')
      }).then(error => {
        this.$store.dispatch('loader/done')
        console.log(error)
      })
    },
    getTypes () {
      const data = {
        idcontacto: this.value.idcontacto,
        idproducto: this.value.idproducto
      }
      this.types = []
      this.subtypes = []
      this.value.idtipo = 0
      this.value.idsubtipo = 0
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('typing/getTypes', data).then(response => {
        this.types = response
        this.$store.dispatch('loader/done')
      }).then(error => {
        this.$store.dispatch('loader/done')
        console.log(error)
      })
    },
    getSubtypes () {
      const data = {
        idcontacto: this.value.idcontacto,
        idproducto: this.value.idproducto,
        idtipo: this.value.idtipo
      }
      this.subtypes = []
      this.value.idsubtipo = 0
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('typing/getSubtypes', data).then(response => {
        this.subtypes = response
        this.$store.dispatch('loader/done')
      }).then(error => {
        this.$store.dispatch('loader/done')
        console.log(error)
      })
    },
    getStates () {
      this.$store.dispatch('loader/pending')
      this.$store.dispatch('typing/getStates').then(response => {
        this.states = response
        this.$store.dispatch('loader/done')
      }).then(error => {
        console.log(error)
      })
    },
    getUsers (search) {
      const data = {
        pagina: 1,
        cantidad: 10,
        filtrobuscar: search
      }
      this.$store.dispatch('caseStore/getUsers', data).then(response => {
        this.users = response.data.map(user => {
          return {
            ...user,
            name: `${user.nombre} ${user.apellido}`
          }
        })
        this.loading = false
      }).then(error => {
        console.log(error)
      })
    },
    getUserAssigend () {
      this.$store.dispatch('caseStore/getUserAssigned', { id: this.value.idusuarioasignado }).then(user => {
        this.userAssigned = user
        this.getUsers(this.userAssigned.nombre)
      }).catch(error => {
        console.log(error)
      })
    },
    getLoggedUser () {
      this.userAssigned = this.$store.getters.getLoggedUser
      this.getUsers(this.userAssigned.nombre)
    },
    validateForm () {
      return this.$refs.form.validate()
    }
  },
  watch: {
    value: {
      handler (value) {
        if (this.userAssigned) {
          value.idusuarioasignado = this.userAssigned.id
        }
        this.$emit('input', value)
      },
      deep: true
    },
    'value.idusuarioasignado': {
      handler (value) {
        this.getUserAssigend()
      }
    },
    'value.idcontacto': {
      handler (value) {
        this.getProducts()
      }
    },
    search: {
      handler (v) {
        if (v && v.length > 3) {
          this.loading = true
          this.getUsers(v)
        }
      }
    }
  },
  mounted () {
    // this.$refs.form.$children[1].focus()
    window.scrollTo(0, 0)
    this.getStates()
    this.getUsers()
    if (this.isEdit) {
      this.getTypes()
      this.getSubtypes()
    } else {
      this.getLoggedUser()
    }
  }
}
</script>

<style>
</style>
